import React, { useContext, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { GlobalState } from "../../../GlobalState";
import ProductItem from "../utils/productItem/ProductItem";

function DetailProduct() {
  const params = useParams();
  const state = useContext(GlobalState);
  const [products] = state.productsAPI.products;
  const addCart = state.userAPI.addCart;
  const [detailProduct, setDetailProduct] = useState([]);

  useEffect(() => {
    if (params.id) {
      products.forEach((product) => {
        if (product._id === params.id) setDetailProduct(product);
      });
    }
  }, [params.id, products]);

  if (detailProduct.length === 0) return null;

  return (
    <>
      <div className="detail">
        <img src={detailProduct.images.url} alt="" />
        <div className="box-detail">
          <div className="row">
            <h2>{detailProduct.title}</h2>
            <h6>#id: {detailProduct.product_id}</h6>
          </div>
          <span> ₹ {detailProduct.price}</span>

          <div className="section-heading">
            <h3>Description</h3>
            <p>{detailProduct.description}</p>
          </div>

          <div className="section-heading">
            <h3>Product Content</h3>
            <p>{detailProduct.content}</p>
          </div>

          <p>Ordered: {detailProduct.sold}</p>
          
          <Link
            to="/cart"
            className="cart"
            onClick={() => addCart(detailProduct)}
          >
            Buy Now
          </Link>
        </div>
      </div>

      <div>
        <h2>Related products</h2>
        <div className="products">
          {products.map((product) =>
            product.category === detailProduct.category ? (
              <ProductItem key={product._id} product={product} />
            ) : null
          )}
        </div>
      </div>
    </>
  );
}

export default DetailProduct;
